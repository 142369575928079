import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import StyledButton from '../../components/Button';
import ContactFormCom from '../../components/ContactFormCom';
import SingleOffersGallery from '../../components/SingleOffersGallery';
import Offers from '../../components/Offers';
import SEO from '../../components/SEO';

const HeroStyledWrapper = styled.div`
  position: relative;
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 100px;
`;

const HeroStyledHeaders = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

const HeroStyledHeader = styled.h1`
  position: relative;
  z-index: 9997;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.font.size.m};
  font-family: 'Dancing Script', cursive;
  line-height: 2.2rem;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.xxxl};
  }
`;

const HeroStyledImage = styled(GatsbyImage)`
  position: relative;
  height: 30vh;
  width: 100%;
  opacity: 0.8;
`;

const TextStyledWrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 50px;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    margin: 50px 300px 0 300px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const OffersWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryFloristic};
  padding: 2rem;
  margin: 2rem 0;
  text-align: center;
  position: relative;
`;

const StyledTitle = styled.h2`
  font-weight: 600;
  font-size: ${({ theme }) => theme.font.size.s};
  text-transform: uppercase;
  text-shadow: 1px 1px 2px lightgrey;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledTitleOffers = styled(StyledTitle)`
  padding: 1.5rem 0 0 0;
  text-align: center;
`;

const DescriptionWrapper = styled.div`
  line-height: 2.2rem;
  max-width: 800px;
  margin: 2em auto;
`;

const StyledH3 = styled.h3`
  color: #59388f;
  text-decoration: underline;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Dancing Script', cursive;
  line-height: 4rem;
  font-size: ${({ theme }) => theme.font.size.s};

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
`;

const StyledParagraph = styled.p`
  margin: 4rem 0;
`;

export default function LasWSloiku({ data }) {
  return (
    <>
      <SEO title="Las w słoiku" />
      <HeroStyledWrapper>
        <HeroStyledHeaders>
          <HeroStyledHeader>{data.singleOffer.title}</HeroStyledHeader>
        </HeroStyledHeaders>
        <HeroStyledImage image={data.singleOffer.image.asset.gatsbyImageData} />
      </HeroStyledWrapper>
      <TextStyledWrapper>
        <DescriptionWrapper>
          <StyledParagraph>
            To wyśmienita oraz <b>długowieczna dekoracja każdego wnętrza</b>:
            domowego zacisza, biura, restauracji czy hotelu. W środku życie
            toczy się swoim tempem przez co obserwowanie go oraz poznawanie za
            pomocą węchu daje nam <b>niesamowite doznania</b>. To również
            edukacyjna zabawa dla dzieci pozwalająca na „podglądanie” zjawisk
            oraz procesów zachodzących w przyrodzie.
          </StyledParagraph>
          <b>Doskonale sprawdzi się w roli prezentu</b> z okazji zakupu
          mieszkania, urodzin lub podziękowania bliskiej Nam osobie. Dla tych,
          którzy nie posiadają jeszcze dużego doświadczenia w pielęgnowaniu
          roślin będzie doskonałym krokiem na zapoczątkowanie przygody w
          zamkniętym świecie flory.
          <StyledParagraph>
            W naszej ofercie można znaleźć{' '}
            <b>lasy w słoiku otwarte oraz zamknięte</b>. Te drugie cechują się
            różnorodnością roślin. Można znaleźć tutaj odmiany paproci (np.
            Adiantum, Pteris – Orliczka), bluszcze, fitonie, palmy, maranty,
            widliczki itp.).
          </StyledParagraph>
          <StyledParagraph>
            Natomiast słoje otwarte wspaniale prezentują się z kaktusami i
            sukulentami tworząc tzw. <b>ogrody pustynne</b>. Nie wymagają
            szczególnej troski oraz opieki. Wystarczy jeżeli raz na jakiś czas
            zaserwujemy im odrobinę wody, a na pewno odwdzięczą się swoją
            okazałością.
          </StyledParagraph>
          <StyledParagraph>
            Zarówno w słojach zamkniętych jak i otwartych użycie naturalnych
            dodatków takich jak mech, kora, kamienie, żwirek oraz dekoracyjnych:
            ławeczki, schodki, mostki nadadzą niepowtarzalny klimat całości.
            Sprawią, że w naszych czterech kątach będziemy mieć swój{' '}
            <b>„własny kawałek przyrody”.</b>
          </StyledParagraph>
          <StyledH3>OFERUJEMY</StyledH3>
          <StyledList>
            {data.singleOffer.listOfOffers.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </StyledList>
          <ButtonWrapper>
            <StyledButton as={Link} to="/kontakt">
              Skontaktuj się z nami
            </StyledButton>
          </ButtonWrapper>
        </DescriptionWrapper>
      </TextStyledWrapper>
      <OffersWrapper>
        <StyledTitleOffers>nasze realizacje</StyledTitleOffers>
        <SingleOffersGallery offerTitle="Las w słoiku" />
        <ButtonWrapper>
          <StyledButton className="white" as={Link} to="/galeria/las-w-sloiku">
            Zobacz więcej zdjęć
          </StyledButton>
        </ButtonWrapper>
      </OffersWrapper>
      <ContactFormCom />
      <OffersWrapper>
        <StyledTitleOffers>sprawdź nasze inne oferty</StyledTitleOffers>
        <Offers />
      </OffersWrapper>
    </>
  );
}

export const query = graphql`
  query LasWSloikuQuery {
    singleOffer: sanityOffer(slug: { current: { eq: "las-w-sloiku" } }) {
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      id
      listOfOffers
      title
      headline
      description
    }
  }
`;
